import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";

interface ComponentData {
  title?: string;
  content?: string;
  additionalTexts?: string[];
  expanded: boolean;
}

const what: ComponentData[] = [
  {
    title: "(1) Branding",
    content:
      " Uncover the unique essence of your brand through our creative approach. At Diceee Studio, we shape ideas into memorable visual identities, capturing what makes you special.",
    additionalTexts: ["Brand Identity", "Brand Strategy"],
    expanded: false,
  },
  {
    title: "(2) Development",
    content:
      "At Diceee Studio, we turn your concepts into exceptional digital experiences, crafting functional and captivating solutions that stand out.",
    additionalTexts: ["Front-End", "E-Commerece", "SEO"],
    expanded: false,
  },
  {
    title: "(3) Video and more",
    content:
      "Dive into the storytelling power of our video, post-production, and 3D animation services. From captivating videos to dynamic multimedia content, Diceee Studio brings your ideas to life, helping you shine and effectively convey your message.",
    additionalTexts: ["Video Edition", "Post-Production" , " Motion Graphics", "Visual Effects", " 3D Animation"],
    expanded: false,
  },
  // Agrega más objetos de componente según sea necesario
];

const slideIn = keyframes`
  0% {
    transform: translateY(500%);
  }
  100% {
    transform: translateY(0); 
  }
`;

const ComponentWrapper = styled.div`
  width: 75%;
  margin-bottom: 2rem;
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TitleText = styled.div`
  font-size: 2rem;
  margin-right: 1rem;
`;

const Icon = styled.div`
  font-size: 2rem;
`;

const ContAdditionalText = styled.div`
margin-top: 1rem;
display: flex;
flex-direction: row;
align-items: flex-start;
flex-wrap: wrap;
`;

const AdditionalText = styled.div`
  margin: 0.5rem 0.5rem 0rem 0rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1.5rem;
  line-height: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  color: ${colors.green};
  border: solid 1px ${colors.green};
`;

const Content = styled.div<ComponentData>`
  font-size: 1.25rem;
  margin: 1rem 0px 0px 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  animation: ${slideIn} 0.5s both;
  transition: transform 0.5s, max-height 1s, opacity 0.5s;

  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
  max-height: ${({ expanded }) => (expanded ? "1000px" : "0px")};
  transform: ${({ expanded }) =>
    expanded ? "translateY(0%)" : "translateY(500%)"};
`;

const Cont = styled.div`
  margin-top: 1rem;
  width: 85%;
  color: ${colors.white};
  text-align: left;

  display: flex;

  flex-direction: column;
  align-items: flex-start;
`;

const WhatWeDo: React.FC = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleExpansion = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Cont>
      {what.map((component, index) => (
        <ComponentWrapper key={index}>
          <TitleWrapper onClick={() => toggleExpansion(index)}>
            <TitleText>{`${component.title}`}</TitleText>
            <Icon>{expandedIndex === index ? "-" : "+"}</Icon>
          </TitleWrapper>
          <Content expanded={expandedIndex === index}>
            {component.content}
            <ContAdditionalText>
              {component.additionalTexts?.map((text, i) => (
                <AdditionalText key={i}>{text}</AdditionalText>
              ))}
            </ContAdditionalText>
          </Content>
        </ComponentWrapper>
      ))}
    </Cont>
  );
};

export default WhatWeDo;
