import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";

const FooterIn = keyframes`
  from {margin-bottom: -200px; opacity: 0;}
  25%, to {margin-top:0; opacity: 1;}
`;

const Cont = styled.footer`
  margin-top: 100px;
  color: #fff;
  width: calc(85% - 8rem);
  padding: 4rem;
  min-height: 400px;
  display: flex;

  flex-direction: column;
  align-items: flex-start;

  justify-content: normal;
  border-radius: 32px;
  border: 1px solid ${colors.white};

  @media only screen and (max-width: 991px) {
    width: calc(85% - 4rem);
    padding: 2rem;
  }
`;

const Title = styled.p`
  color: ${colors.green};
  font-size: 4rem;
  margin: 10px 0px 24px 0px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6.5px;
  text-transform: uppercase;

  @media only screen and (max-width: 991px) {
    margin: 10px 0px 24px 0px;
    font-size: 3rem;
  }
  @media screen and (max-width: 479px) {
    margin: 10px 0px 24px 0px;
    font-size: 2rem;
  }
`;

const Sub = styled.p`
  color: ${colors.gray};
  margin: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.5px;
`;

const Text = styled.p`
  color: ${colors.white};
  margin: 0;
  font-size: 1.5rem;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2.4px;
  text-decoration: none;

  @media screen and (max-width: 479px) {
    font-size: 1rem;
  }
`;

const Copy = styled.p`
  margin: 2rem auto;
  color: ${colors.gray};
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
`;

const Section = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 0.75fr 0.75fr;
  margin-top: 3rem;

  @media only screen and (max-width: 991px) {
    grid-template-columns: none;
  }
`;

const Column = styled.div`
  grid-area: span 1 / span 1 / span 1 / span 1;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;

  a {
    color: ${colors.white};
    font-size: 1.5rem;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 2.4px;
    text-decoration: none;

    @media screen and (max-width: 479px) {
      font-size: 1rem;
    }
  }

  .mail {
    vertical-align: super;
    text-decoration-line: underline;
  }
`;

const CopyIcon = styled.button`
  vertical-align: bottom;
  display: inline-block;
  margin-left: 10px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: none;
  border-radius: 8px;

  background-color: ${colors.transparent};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='190' height='190' viewBox='0 0 190 190' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24 15H116C120.971 15 125 19.0294 125 24V116C125 120.971 120.971 125 116 125H24C19.0294 125 15 120.971 15 116V24C15 19.0294 19.0294 15 24 15ZM0 24C0 10.7452 10.7452 0 24 0H116C129.255 0 140 10.7452 140 24V50H166C179.255 50 190 60.7452 190 74V166C190 179.255 179.255 190 166 190H74C60.7452 190 50 179.255 50 166V140H24C10.7452 140 0 129.255 0 116V24ZM65 140V166C65 170.971 69.0294 175 74 175H166C170.971 175 175 170.971 175 166V74C175 69.0294 170.971 65 166 65H140V116C140 129.255 129.255 140 116 140H65Z' fill='%235FF900' /%3E%3C/svg%3E");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;

  transition: background-image 0.2s, background-color 0.2s;

  &:hover {
    background-color: ${colors.green};
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='190' height='190' viewBox='0 0 190 190' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24 15H116C120.971 15 125 19.0294 125 24V116C125 120.971 120.971 125 116 125H24C19.0294 125 15 120.971 15 116V24C15 19.0294 19.0294 15 24 15ZM0 24C0 10.7452 10.7452 0 24 0H116C129.255 0 140 10.7452 140 24V50H166C179.255 50 190 60.7452 190 74V166C190 179.255 179.255 190 166 190H74C60.7452 190 50 179.255 50 166V140H24C10.7452 140 0 129.255 0 116V24ZM65 140V166C65 170.971 69.0294 175 74 175H166C170.971 175 175 170.971 175 166V74C175 69.0294 170.971 65 166 65H140V116C140 129.255 129.255 140 116 140H65Z' fill='%23000000' /%3E%3C/svg%3E");
  }
`;

export const ContactStyle = {
  Cont,
  Title,
  Sub,
  Text,
  Section,
  Column,
  Copy,
  CopyIcon,
};
