import React from 'react';
import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCEkb5rB2eyUaj3Qnj0ZQ-qYsRGjnGy24E",
  authDomain: "diceee-website.firebaseapp.com",
  projectId: "diceee-website",
  storageBucket: "diceee-website.appspot.com",
  messagingSenderId: "128671626463",
  appId: "1:128671626463:web:7e6225aa96cbfb96c9656a",
  measurementId: "G-QCWX5MBKLN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  
  document.title = "Diceee Studio";
  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
