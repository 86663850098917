import React from "react";
import { WorkStyles } from "./WorkStyles";

const Works = () => {
  return (
    <>
    <WorkStyles.Cont>
      <WorkStyles.Video>
        <iframe
          src="https://player.vimeo.com/video/859558409?background=1"
          frameBorder={0}
          allow="autoplay; fullscreen"
          allowFullScreen={false}
          style={{
            position: 'absolute',
            top:0,
            left:0,
            width:'100%',
            height:'100%'
        }}
        ></iframe>
      </WorkStyles.Video>
      </WorkStyles.Cont>
    </>
  );
};

export default Works;