import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";
import { ReactNode } from "react";

interface AnimatedCharacterProps {
  index: number;
  children: ReactNode;
  delay?: number;
}

const HomeIn = keyframes`
  from {opacity: 0;}
  25%, to {opacity: 1;}
`;

const slideIn = keyframes`
  0% {
    transform: translateY(1000%) ;
  }
  100% {
    transform: translateY(0);
  }
`;

const AnimatedCharacter = styled.span<AnimatedCharacterProps>`
  display: inline-block;
  overflow: hidden;
  animation: ${slideIn} 1s ${(props) => props.index * 0.1}s both;

  color: ${colors.green};
  font-style: normal;
  font-weight: 400;
  letter-spacing: 8.2px;
  text-transform: uppercase;
  text-align: left;
  white-space: pre;
`;

const All = styled.div`
  animation-name: ${HomeIn};
  animation-fill-mode: both;
  animation-duration: 3s;
  animation-delay: 0.5;
`;

const ContTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 85%;
  text-align: left;
`;

const Title = styled.div`
  margin: 0;
  font-size: 5rem;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    font-size: 2.7rem;
  }

  @media only screen and (max-width: 991px) {
    font-size: 2rem;
  }
`;

const NormalTitle = styled.p`
  margin-top: 4rem;
  width: 85%;
  display: inline-block;

  color: ${colors.green};
  font-style: normal;
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 8.2px;
  text-transform: uppercase;
  text-align: left;
  white-space: pre;

  @media only screen and (max-width: 767px) {
    font-size: 2.7rem;
  }

  @media only screen and (max-width: 991px) {
    font-size: 2rem;
  }
  `;

const ContSub = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 85%;
  overflow: hidden;
  text-align: left;
`;

const Sub = styled.h1`
  margin: 0;
  color: ${colors.white};

  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 165.023%; /* 33.005px */
  letter-spacing: 2px;

  animation: ${slideIn} 1s 0.5s both;

  @media only screen and (max-width: 991px) {
    font-size: 1rem;
  }
`;

const ReelButton = styled.a`
  align-self: center;
  width: 150px;
  height: 40px;
  color: ${colors.green};
  background-color: ${colors.transparent};
  border-radius: 40px;
  border: solid 2px ${colors.green};
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
`;

export const HomeStyles = {
  All,
  ContTitle,
  ContSub,
  Title,
  NormalTitle,
  Sub,
  ReelButton,
  AnimatedCharacter,
};
