import React from "react";
import { HeaderStyle } from "./HeaderStyle";

const Header = () => {
  return (
    <HeaderStyle.Header>
      <HeaderStyle.Nav>
        <HeaderStyle.Ul align="start">
          <HeaderStyle.Li>
            <HeaderStyle.Icon />
          </HeaderStyle.Li>
        </HeaderStyle.Ul>
      </HeaderStyle.Nav>

      <HeaderStyle.Nav>
        <HeaderStyle.Ul align="end">
          <a href="#contact">
            <HeaderStyle.Li>Let's Talk</HeaderStyle.Li>
          </a>
        </HeaderStyle.Ul>
      </HeaderStyle.Nav>
    </HeaderStyle.Header>
  );
};

export default Header;
