import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { colors } from "../../styles/colors";

interface LoaderProps {
  isExiting: boolean;
  size?: number;
  delay?: number;
}

interface LoaderScreenProps {
  isExiting?: boolean;
}

const load = keyframes`
  0% {
    transform: rotate(0deg);
}
  100% {
    transform:  rotate(720deg);
  }
`;

const fadeOutAll = keyframes`
  from { filter: blur(0);opacity: 1;}
  to {filter:  blur(100px); opacity: 0;}
`;

const LoaderCont = styled.div<LoaderProps>`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  animation: ${(props) =>
    props.isExiting
      ? css`
          ${fadeOutAll} 1s ease forwards
        `
      : css``};
`;

const Loader = styled.div`
  z-index: 100;
  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 5em;
  height: 5em;
  animation: ${load} 2.2s infinite;
  background-size: 100%;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='116' height='116' viewBox='0 0 116 116' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M39.5455 0C17.7051 0 0 17.7051 0 39.5455V76.4546C0 98.2949 17.7051 116 39.5455 116H76.4546C98.2949 116 116 98.2949 116 76.4546V39.5455C116 17.7051 98.2949 0 76.4546 0H39.5455ZM78.8801 25.5199C85.2866 25.5199 90.4801 30.7134 90.4801 37.1199C90.4801 43.5264 85.2866 48.7199 78.8801 48.7199C72.4736 48.7199 67.2801 43.5264 67.2801 37.1199C67.2801 30.7134 72.4736 25.5199 78.8801 25.5199ZM37.1201 90.4799C30.7136 90.4799 25.5201 85.2864 25.5201 78.8799C25.5201 72.4734 30.7136 67.2799 37.1201 67.2799C43.5266 67.2799 48.7201 72.4734 48.7201 78.8799C48.7201 85.2864 43.5266 90.4799 37.1201 90.4799Z' fill='%235FF900'/%3E%3C/svg%3E");
`;

const LoaderScreen: React.FC<LoaderScreenProps> = ({ isExiting = false }) => {
  return (
    <LoaderCont isExiting={isExiting}>
      <Loader />
    </LoaderCont>
  );
};

export default LoaderScreen;
