import React, { ReactNode, useEffect, useState } from "react";
import { HomeStyles } from "./HomeStyles";
import LoaderScreen from "../Loader/Loader";
import ContactFooter from "../ContactFooter/ContactFooter";
import Header from "../Header/Header";
import Works from "../Work/Work";
import WhatWeDo from "../WhatWeDo/WhatWeDo";


interface TitleProps {
  children: ReactNode;
}

const Home = () => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaderExiting, setIsLoaderExiting] = useState(false);
  
  useEffect(() => {
    setTimeout(() => {
      setIsLoaderExiting(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 3000);
  }, []);
  
  
  let globalIndex = 0; 
  
  const Title: React.FC<TitleProps> = ({ children }) => {
    if (typeof children === "string") {
      const characters = children.split(' ');
      return (
        <HomeStyles.Title>
          {children.split(' ').map((char, index) => {
            const localIndex = globalIndex - characters.length;
            globalIndex++;
            return (
              <HomeStyles.AnimatedCharacter key={index} index={localIndex}>
                {char + ' '}
              </HomeStyles.AnimatedCharacter>
            );
          })}
        </HomeStyles.Title>
      );
    }
  
    return <HomeStyles.Title>{children}</HomeStyles.Title>;
  };
  
  
  return (
    <>
    {isLoading ? (
         <LoaderScreen isExiting={isLoaderExiting}/>
      ) : (<HomeStyles.All>
        {/* <div className="noise" /> */}
        <Header />
        <section>
          <HomeStyles.ContTitle>
            <Title children={`We shape fate`} ></Title>
            <Title children={`not just roll the dice.`} ></Title>
            {/* <HomeStyles.ReelButton>WATCH REEL</HomeStyles.ReelButton> */}
          </HomeStyles.ContTitle>
          <HomeStyles.ContSub>
          <HomeStyles.Sub>Diceee Studio  is not just a design studio;</HomeStyles.Sub>
            <HomeStyles.Sub>Our mission is to unite visionary design with purposeful execution.</HomeStyles.Sub>
          </HomeStyles.ContSub>
          {/* <StickersParallax /> */}
        </section>

        
        <section id="work" className="normal">
          <Works />
        </section>

        <section>
          
        <HomeStyles.NormalTitle children={`What we do`} ></HomeStyles.NormalTitle>
      <WhatWeDo/>
        </section>


        <section id="contact">
          <ContactFooter />
        </section>
    </HomeStyles.All>
      )}
    </>
  );
};

export default Home;
