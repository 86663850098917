import React, { useState } from "react";
import { ContactStyle } from "./ContactFooterStyles";
import Toast from "../Toast/Toast";

const ContactFooter = () => {
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToast = (message: string) => {
    setToastMessage(message);
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 3000); // Ocultar después de 3 segundos
  };

  const copyToClipboard = () => {
    const emailElement = document.querySelector(".mail") as HTMLAnchorElement;

    if (emailElement) {
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = emailElement.textContent || "";
      document.body.appendChild(tempTextArea);
      tempTextArea.select();

      try {
        document.execCommand("copy");
        showToast("Copied in Clipboard");
      } catch (err) {
        console.error("Copy to Clipboard failed.", err);
        showToast("Copy to Clipboard failed.");
      }

      document.body.removeChild(tempTextArea);
    }
  };

  return (
    <>
      {toastVisible && <Toast message={toastMessage} />}
      <ContactStyle.Cont>
        <ContactStyle.Title>Let's Talk.</ContactStyle.Title>
        <ContactStyle.Section>
          <ContactStyle.Column>
            <ContactStyle.Sub>Let’s start a conversation</ContactStyle.Sub>
            <div>
              <a href="mailto:diceeestudio@gmail.com" className="mail">
                diceeestudio@gmail.com
              </a>
              <ContactStyle.CopyIcon onClick={copyToClipboard} />
            </div>
          </ContactStyle.Column>
          <ContactStyle.Column>
            <ContactStyle.Sub>Based on</ContactStyle.Sub>
            <ContactStyle.Text>Montevideo, UY</ContactStyle.Text>
          </ContactStyle.Column>

          <ContactStyle.Column>
            <ContactStyle.Sub>Follow us</ContactStyle.Sub>
            <a href="https://dribbble.com/diceeestudio/shots" target="_SEJ">
              dribbble↘
            </a>
            <a href="https://www.instagram.com/diceeestudio/" target="_SEJ">
              instagram↘
            </a>
            <a href="https://twitter.com/diceeestudio" target="_SEJ">
              x↘
            </a>
          </ContactStyle.Column>
        </ContactStyle.Section>
        
      </ContactStyle.Cont>

      <ContactStyle.Copy>© 2023 Diceee Studio</ContactStyle.Copy>
    </>
  );
};

export default ContactFooter;
