import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";

interface ToastProps {
  isVisible: boolean;
}

interface Props {
  message: string;
}

const slideIn = keyframes`
  0% {
    transform: translateY(200%) ;
  }
  100% {
    transform: translateY(0);
  }
`;

const Cont = styled.div`
  position: fixed;
  bottom: 20px;
  width: 100vw;
  display: flex;
  align-items: center;
`;

const ToastContainer = styled.div<ToastProps>`
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 8px;
  color: ${colors.black};
  background-color: ${colors.green};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  animation: ${slideIn} 0.2s both;
  z-index: 1000;
`;

const Toast: React.FC<Props> = ({ message }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 2000); // Ocultar después de 1 segundo

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Cont>
      <ToastContainer isVisible={isVisible}>{message}</ToastContainer>
    </Cont>
  );
};

export default Toast;
