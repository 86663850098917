import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";

const slideIn = keyframes`
  0% {
    transform: translateY(200%); opacity: 0;
  }
  100% {
    transform: translateY(0); opacity: 1;
  }
`;

const Cont = styled.div`

width: 85%;

`;


const Video = styled.div`
  isolation: isolate;
  border-radius: 1.5rem;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
`;


export const WorkStyles = {
  Cont,
  Video,
};
